<template>
    <div class="mx-0 p-0">
        <!-- There is a nav element and a nav class for browser versatility.  only the div with class will be styled -->
        <!-- <div class="mnav w-100 d-flex justify-content-center align-content-end py-2"> 
            <div class="col-xl-8 col-lg-11 col-xs-12">
                <ul class="d-inline-flex justify-content-between align-content-end m-auto  w-100 ">
                    <li class="d-inline align-self-end">
                        <img src="@/assets/images/logo.png" alt="" class="nav-logo ">
                    </li>
                    <li class="d-inline align-self-end">
                        
                    </li>
                </ul>
            </div> 
        </div> -->



        <main class="m-0 p-0 main-container">
            <!-- <div id="NavButtonsContainer" class="d-block">
                <a id="ShoppingCartButton" role='button' class="mb-3 m-0 p-0 d-block">
                    <img src="~@/assets/images/shoppingCartButton.svg" alt="" width="72px" height="72" class="">
                </a>
                <a id="ShoppingCartButton" role='button' class="mb-3 m-0 p-0 d-block">
                    <img src="~@/assets/images/hamButton.svg" alt="" class="">
                </a>
            </div> -->
            <slot/>
        </main>


        <footer class="">

        </footer>

    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>


