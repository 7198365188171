<template>
  <component :is="layout">
    <router-view/>
  </component>    
</template>

<script>
import Landing from './layouts/Landing.vue';
import Admin from './layouts/Admin.vue';
// import Store from './layouts/Store.vue';
import Store from './layouts/Store'
export default {
  name: 'App',
  components: {
    Landing,
    Admin,
    Store,
  },
  computed: {
    layout(){
      return (this.$route.meta.layout || 'landing')
    }
  }
}
</script>

