<template>
    <div  v-if="display === 'CART'" class="row d-flex justify-content">
        <div class="col-12">
            <Cart/>
        </div>
    </div>
    <div  v-else-if="display === 'PRODUCT' && !isMobile" class="row d-flex justify-content">
        <div class="col-12">
            <product-popup  ref="a" @close="closePopup()" :product="selected"  />
        </div>
    </div>
    <div v-else @scroll="handleScroll()" class="row d-flex justify-content-center px-2" >
        <div class="col-xs-12 col-md-12 col-xl-9 col-xxl-8 my-0 ">
            <div  class="row d-flex justify-content-start px-1">

                <div v-for="product,i in storeProducts[storePathString]?.products" :key="i"  :class="selected === product && isMobile ? 'col-xl-9 col-md-8 col-xs-12 mb-3' : 'col-xl-3 col-md-4 col-sm-6 col-xs-12 p-1 mb-3' ">
                    <product-popup v-if="display == 'PRODUCT' && index==i && isMobile" ref="b" @close="closePopup()" :product="selected" />
                    <div v-else class="w-100 p-0 product-card" @click="openPopup(product,i)">
                        <div :style="{'background-image': 'url(' + product.images[0] + ')'}"  class="image-container">
                        </div>

                        <div class="product-text w-100   px-2 py-2">
                            <h4 class="title " >{{product.title}}</h4>
                            <h5 class="price ">${{product.price / 100}}</h5>
                        </div>

                    </div>
                </div>
                <div  ref="lazyLoader" class="col-xl-3 col-md-4 col-sm-6 col-xs-12 product-card last"></div>
                <!-- <div   class="col-xl-3 col-md-4 col-sm-6 col-xs-12 product-card last">
                    <button class="btn btn-primary my-2" @click="loadMoreProducts">Load More</button>
                </div> -->

            </div>
        </div>
    </div>
                <InfiniteLoading v-if="true" @infinite="loadMoreProducts" />

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import InfiniteLoading from "v3-infinite-loading";
import ProductPopup from '../components/products/ProductPopup.vue'
import Cart from '../components/cart/cart.vue'
export default {
    name:'Store',
    components:{ProductPopup,Cart,InfiniteLoading},
    data(){
        return {
            selected: null,
            showPopup: false,
            index: 0,
            isMobile:false,
        }
    },
    updated(){
        // this.selected = this.storeProducts[this.storePathString].products[this.index]
    },
    methods:{
        ...mapMutations(['setDisplay']),
        ...mapActions(['loadMoreProducts']),
        closePopup(){
            this.index = null;
            this.selected = null;
            this.setDisplay('DEFAULT')
        },
        openPopup(product, i){
            this.index = i
            this.selected = product
            this.setDisplay('PRODUCT')
        },
        handleScroll(){
            let wheight = window.innerHeight
            let r = this.$refs['lazyLoader']?.getBoundingClientRect().top
            let distance_from_top = r - wheight 
            if (distance_from_top <= 200 && !this.lazyLoading){
                this.loadMoreProducts()
            } 
        }
    },
    computed:{
        ...mapState({
            storePathString: state => state.storePathString,
            storeProducts: state => state.storeProducts,
            display: state => state.displayPanel,
            lazyLoading: state => state.lazyLoading,
        })
    },
    created(){
        window.addEventListener("scroll", function() {
            console.log('Scrolling')
        });
        },
    mounted(){
        
        this.isMobile = window.innerWidth < 550
    },
}
</script>

<style lang="scss">
@media only screen and (max-width: 550px) {
    .product-card{
        min-height:23rem !important;
        max-height:23rem !important;
        .image-container{
            min-height: 19rem !important;
            max-height: 19rem !important;
        }
    }
}
.product-card{
    min-height:19rem;
    max-height:19rem;
    // outline: 2px solid #f1f1f156;
    contain:content;
    // background:#e4e4e4;
    backdrop-filter: blur(15px);
    .image-container{
        border-radius: .37rem;
        min-height:15rem;
        max-height: 15rem;
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center center;

    }
    .product-text{
        // box-shadow: 0px 0px 2px #e7e4e4d6;
        min-height: 4.5rem;
        max-height: 4.5rem;
        vertical-align: center;
        // background: radial-gradient(#aeaeaeb8, #d8d8d8);
        color: #ffffff;
        contain:content;
        height:auto; 
        margin:auto auto;
        .title{
            margin:auto auto;
            vertical-align: center;
            font-size: 1rem;
            line-height: 1rem;
            min-height: 2rem;
            max-height: 2rem;
            contain:content;
            font-weight:600;
        }
        .price{
            float:left;
            padding-top: .2rem;
            font-size: 1rem;
            text-align: left;
        }
    }
    .image{
        background: #bfbfbfb8;
    }
}

.last{
    background:none !important;
    border:none !important;
    box-shadow:none !important;
    outline:none !important;
}

</style>