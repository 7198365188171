<template>
    <div id="wrapper" class="w-100">
      <div class="fixed-bar">
        <nav class="navbar navbar-expand-lg d-flex justify-content-between  w-100">
            <div class="">
                <button @click="toggleSidebar" type="button" id="sidebarCollapse" class="btn btn-light mx-2">
                    <i class="fas fa-align-left"></i>
                </button>
                <button @click="$router.push('/store')" type="button" id="sidebarCollapse" class="btn btn-light mx-2">
                    <i class="fas fa-store"></i>
                </button>
            </div>
          
          <button @click="signout" type="button" id="sidebarCollapse" class="btn btn-light mx-2">
            <i class="fas fa-arrow-right-from-bracket"></i>
          </button>
        </nav>
      </div>
      <div id="content" class="w-100 d-flex bg-light">
        <side-bar :Menu="menu" :IsAdmin="true"></side-bar>
        <main class="container-fluid w-100 main-wrap main-wrap-fixed py-2 mb-5">
          <slot />
        </main>
    </div>
    </div>
</template>
<script>
import SideBar from './Sidebar.vue'
import { mapActions, mapMutations, } from 'vuex';
import { signOut } from '@firebase/auth';
import { auth } from '../firebase';

export default {
    name:'AdminLayout',
    components:{SideBar},
    data(){
        return {
            isSmallScreen: false,
            collapsed: true,
        menu: [
          {
            title: 'Orders',
            icon: 'fa fa-box',
            name: 'DEFAULT'
          },
          {
            title: 'New Product',
            parent: 'Products',
            icon: 'fa fa-plus',
            name: 'CREATE_PRODUCT',
          },
          {
            title: 'Brands',
            icon: 'fa fa-award',
            name: 'BRANDS',
          },
          {
            title: 'Categories',
            icon: 'fa fa-list',
            name: 'CATEGORIES',
          },
          {
            title: 'Users',
            icon: 'fa fa-user',
            name: 'USERS',
          },
        ]
        } 
    },
    setup(){
        },
    methods:{
        ...mapMutations(['setDisplay','toggleSidebar']),
        ...mapActions(['getCategories','getSubCategories','getBrands','getUser']),
        getScreenSize(){
            this.isSmallScreen = screen.availWidth < 760
        },
        sidebarClick(e, item){
            // console.log('Item Clicked',item);
            if(item.name){
              this.setDisplay(item.name)
            }
        },
        onCollapse(collapsed){
            this.isCollapsed = collapsed
        },
        signout(){
            signOut(auth).then(() => {
                window.location.href = '/store'
            })
        }
    },
    mounted(){
        window.addEventListener('resize',this.getScreenSize);
        this.getCategories()
        this.getSubCategories()
        this.getBrands()
        this.getUser()
    },
    computed:{
      
    }

};
</script>
<style scoped lang="scss">
#wrapper{
  overflow: hidden !important;
  overflow:-moz-hidden-unscrollable;
}
#content{
  top:54px;
  position:fixed;
  height:calc(100vh - 54px) !important;
}

.main-wrap{
    position:relative;
    height:calc(100vh -  54px);
    overflow: hidden;
    overflow-y:scroll;
}
@media (max-width:576px) {
    .main-wrap-fixed{
        position:fixed ;
        left:0px;
    }

}
.fixed-bar{
  min-width:100vw;
  min-height:54px;
  top:0px !important;
  position: fixed;
  display:block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: calc(100vh - 54px);
    max-height: calc(100vh - 54px);
    
    overflow: hidden;
    z-index: 100;
}

#sidebar.active {
    margin-left: -250px;
    
}
#sidebar {
    min-width: 250px;
    max-width: 250px;
    // top:0px;
    // position:fixed;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}
p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}
</style>