<template>
    <nav id="sidebar" :class="{'active':collapsed,'sidebar':true}">
        <div class="sidebar-header">
            <img v-if="!collapsed" src="/logo.svg" alt="" class="w-100">
        </div>

        <ul class="list-unstyled components">
            <p>OG Stilo Store</p>
            <li :class="{'active':isActive(item.title)}" v-for="item,key in Menu" :key="key">
                <a role="button" @click="sidebarClick($event,item,null)"  :class="{'expanded': isActive(item.title)}">
                    <i v-if="item.icon" :class="item.icon " style="margin-right:.3rem; width:1rem"></i>{{item.title}}
                </a>
                <ul v-if="item.child" v-show="isActive(item.title)" class="list-unstyled">
                    <li :class="{'active':active}" v-for="subitem,subkey in item.child" :key="subkey">
                        <a @click="sidebarClick($event,subitem,item.title)" :class="{'expanded': isActive(subitem.title)}" ><i v-if="item.icon" :class="item.icon " style="margin-right:.3rem; width:1rem"></i>{{subitem.title}}</a>
                        <ul v-if="subitem.child" v-show="isActive(subitem.title)" class="list-unstyled submenu-2">
                            <li :class="{'active':active}" v-for="ssubitem,subkey in subitem.child" :key="subkey">
                                <a @click="sidebarClick($event,ssubitem,subitem.title)" ><i v-if="item.icon" :class="item.icon " style="margin-right:.3rem; width:1rem"></i>{{ssubitem.title}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>

        </ul>
    </nav>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    name: 'SideBar',
    props:{Menu:{
        type: Array,
        required:true,
    }, IsAdmin:{
        type: Boolean,
        required:false,
        default: false,
    },
        
    },
    data(){
        return {
            active: [],
        }
    },
    methods:{
        ...mapActions(['setStorePath']),
        ...mapMutations(['setDisplay', 'disableForceRedirect']),
        isActive(s){
            return this.active.indexOf(s) >= 0;
        },
        getScreenSize(){
            this.isSmallScreen = screen.availWidth < 760
        },
        sidebarClick(e, item,parent){
            //This is the top level (Categories,Brand,etc)

            if (parent === null) {
                this.active = [item.title]
                if(item.name){
                    this.setDisplay(item.name)
                }
                if (this.IsAdmin)return;
            }
            else if (this.active[this.active.length -1] === parent) this.active.push(item.title);
            else if (this.isActive(parent) && this.active[this.active.length -1] !== parent) {
                while(this.active[this.active.length -1] != parent){
                    this.active.pop()
                }
                this.active.push(item.title)
            }
            if(!item.child){
                this.setStorePath(this.active)
            }
        },
        onCollapse(collapsed){
            this.isCollapsed = collapsed
        },
        handleForcedLoad(){
            var menu = this.Menu
            var parent = null
            for (let p of this.path.split("_")){
                for (let opt of menu){
                    if (opt.title === p){
                        this.sidebarClick({}, opt, parent ? parent.title : parent)
                        if (opt.child){
                            parent = opt
                            menu = opt.child
                        }
                        break;
                    }
                }
            }
            this.disableForceRedirect()
        }
    },
    mounted(){
        window.addEventListener('resize',this.getScreenSize);
        if (this.forceLoad){
            this.handleForcedLoad()
        }else{
            this.setStorePath(["Home"])
        }

    },
    computed:mapState({
        collapsed: state => state.sidebarToggle,    
        path: state => state.storePathString,
        forceLoad: state => state.forceStoreLoad,
    })
}
</script>

<style scoped lang='scss'>


#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: calc(100vh - 54px);
    max-height: calc(100vh - 54px);
    contain:content;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 99;
}

#sidebar.active {
    margin-left: -250px;
    
}


a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}



a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}





#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

ul li a:hover {
    color: var(--Primary) !important;
    background: #fff;
}




</style>