<template>
    <div class="row d-flex justify-content-center mb-5">
        <div class="col-lg-9 col-xl-8 col-md-11 col-xs-12  mb-5">
            <div v-if="loading" class="row d-flex justify-content-center h-100 align-content-center loading-cover">
                <div class="w-auto  bg-light loading-focus fa-2x br-gold">
                    <i class="px-2 my-auto text-primary fa-solid fa-cog d-inline fa-spin"></i>
                    <i class="px-2 my-auto text-warning fa-solid fa-cog d-inline fa-spin fa-spin-reverse"></i>
                    <i class="px-2 my-auto text-danger fa-solid fa-cog d-inline fa-spin"></i>
                    <i class="px-2 my-auto text-info fa-solid fa-cog d-inline fa-spin fa-spin-reverse"></i>
                </div>
            </div>
            <div v-if="!Object.keys(cart).length" class="row d-flex justify-content-center align-content-around  flex-wrap-reverse" style="height: 90vh">
                    <div class="w-auto m-auto" style="" >
                        <h5 class=" text-light m-auto">It looks like your cart is empty fool</h5>
                        <a role="button" @click="setDisplay('DEFAULT')" class="btn btn-light my-2">Back to Store</a>
                    </div>
                    <img src="/Homie_Les.png" alt="" class="w-auto" style="max-height: 10rem">
            </div>
            <div v-else class="row d-flex justify-content-center px-2">
                <table class=" mb-5 br-2 ">
                    <thead>
                        <tr class="header text-light">
                            <td class="text-left"><h3>Cart</h3></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody class="w-100">
                        <tr v-for="product,i in cart" :key="i" @click="null" class="product-row">
                            <td class="text-center">
                                <img :src="product.image" alt="" class="" style="max-width:5rem;">
                            </td>
                            <td class="text-left  text">
                                <div class="row d-flex align-content-around">
                                    <p class="col-12 title mb-0 pb-0 fw-bold" >{{product.title}} </p>
                                    <div class="col-lg-6 col-xs-12 ">

                                        <div class="d-flex w-100 my-auto">
                                            <div class="w-50 field-label">Size</div>
                                            <p class="pb-0 mb-0 w-50 field-value">{{product.size}}</p>
                                        </div>
                                        <div class="d-flex w-100 my-auto pt-2">
                                            <div class="w-50 field-label">Color</div>
                                            <div :style="{'backgroundColor':product.color}" class="color-button "></div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xs-12">
                                        <div class="d-flex w-100 my-auto">
                                            <div class="w-50  field-label">Quantity</div>
                                            <input class="form-control  quantity-input"  :disabled="rejectedCauses[product.id]?.mustRemove" @keyup="verifyWithLatest()" @click="verifyWithLatest()" type="number" min="1" :max="latestProducts[product.id] ? latestProducts[product.id].quantity : 100" v-model="product.quantity" >
                                        </div>
                                    </div>
                                    <div v-if="rejectedCauses[i] ? true : false" class="col-12">
                                        <p class="text-danger mb-0 pb-0">
                                            <i class="fa fa-circle-info mx-2 mb-0"></i>{{rejectedCauses[i].reason}}
                                        </p>
                                    </div>
                                </div>
                                
                            </td>
                            <td>
                                <p class="price fw-bold">${{(product.price / 100).toFixed(2)}}</p>
                                <div class="w">

                                    <button  @click="removeFromCart(product)" class="text-decoration-none badge outline-none badge btn bg-danger">remove</button>
                                </div>
                            </td>
                        </tr>
                        <tr class="product-row totals my-auto ">
                            <td class=""></td>
                            <td class="text-right">Subtotal</td>
                            <td>${{(details.subtotal / 100).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-12">
                    <div :class="isSelected('pickup')" role="button" @click="details.method = 'pickup';DisplayPanels.DETAILS = true;DisplayPanels.ADDRESS = false;">
                        <div class="card-body ">
                            <h5 class="card-title">Store Pickup</h5>
                            <p class="card-text">Schedule your order to be picked up in store.</p>
                        </div>
                    </div>
                    <div :class="isSelected('ship')" @click="DisplayPanels.DETAILS = true; DisplayPanels.ADDRESS = true;details.method = 'ship'" role="button">
                        <div class="card-body  ">
                            <h5 class="card-title">Get It Shipped</h5>
                            <p class="card-text">We will recieve your order and send an invoice as a text or email.  </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="DisplayPanels.DETAILS && Object.keys(cart).length" class="row d-flex justify-content-center mb-3 contact-form">
                <div class="col-md-8 col-sm-12">
                    <form :disabled="loading"  @submit.prevent="createOrder()" class="">
                        <h4 class="section-title mt-3">Contact</h4>
                        <div class="form-group">
                            <input v-model="details.email" required type="email" class="form-control" placeholder="Email" id="email">
                        </div>
                        <div class="form-group">
                            <input v-model="details.phone" required type="phone" class="form-control" placeholder="Phone" id="phone">
                        </div>
                        <div class="form-group">
                            <input v-model="details.first" required type="text" class="form-control" placeholder="First Name" id="first">
                        </div>
                        <div class="form-group">
                            <input v-model="details.last" required type="text" class="form-control" placeholder="Last Name" id="last">
                        </div>
                        
                        <div  v-if="DisplayPanels.ADDRESS" class="mt-5">
                            <h4 class="section-title mt-3">Address</h4>
                            <div class="form-group">
                                <input v-model="details.addr1" required type="address" class="form-control" placeholder="Address Line 1" id="address1">
                            </div>
                            <div class="form-group">
                                <input v-model="details.addr2"  type="address" class="form-control" placeholder="Address Line 2" id="address2">
                            </div>
                            <div class="form-group">
                                <input v-model="details.city" required type="city" class="form-control" placeholder="City" id="city">
                            </div>
                            <div class="w-100 d-flex justify-content-between">
                                <div class="w-50 " >
                                    <div class=" form-group">
                                        <input v-model="details.state" required type="state" class="form-control" placeholder="State" id="state">
                                    </div>
                                </div>
                                <div class="w-50" :style="{'paddingLeft': '1rem'}">
                                    <div class="form-group ">
                                        <input v-model="details.zip" required type="zip" class="form-control" placeholder="Zip" id="zip">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="text-right w-100 d-flex justify-content-md-end justify-content-xs-center mb-5">
                            <button :disabled="loading"  type="submit" class="btn btn-primary mt-3 ml-auto mr-0 w-100">Submit My Order</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { Timestamp, collection, addDoc, getDoc, increment, updateDoc, doc } from 'firebase/firestore'
import {db} from '../../firebase'
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
});
export default {
    name:'Cart',
    components:{},
    data(){
        return {
            DisplayPanels: {
                CART : true,
                FULFILLMENT : false,
                DETAILS : false,
                ADDRESS : false,
                SUBMITTED : false,
                PICKUP_TIME : false,
            },
            latestProducts: {},
            displayPanel: "CART",
            loading: false,
            rejectedCauses: {},
            details: {
                fulfilled:  false,
                method:     null,
                email:      null,
                phone:      null,
                first:      null,
                last:       null,
                addr1:      null,
                addr2:      null,
                city:       null,
                state:      null,
                zip:        null,
                subtotal:   null,
                canceled:   false,
            }
        }
    },
    updated(){
        this.selected = this.storeProducts[this.storePathString].products[this.index]
        this.getSubtotal()
    },
    methods:{
        ...mapActions(['loadMoreProducts']),
        ...mapMutations(['addToCart','removeFromCart', 'newCart','setDisplay']),
        setFulfillmentMethod(method){
            this.details.method = "SHIP"
            if (method == "PICKUP"){
                this.details.method = "PICKUP"
            }
        },
        verifyWithLatest(){
            for (let key in this.cart){
                if (key in this.latestProducts){
                    if(this.cart[key].quantity <= this.latestProducts[key].quantity){
                        if (key in this.rejectedCauses){
                            delete this.rejectedCauses[key]
                        }
                    }else{
                        if(this.rejectedCauses[key]?.db === null){
                            continue
                        }
                        if (key in this.rejectedCauses){
                            this.rejectedCauses[key] = {
                                ...this.rejectedCauses[key],
                                data: this.latestProducts[key],
                                reason: `Available quantity ${this.latestProducts[key].quantity} is less than order quantity ${this.cart[key].quantity}`,
                                mustRemove: false,
                            }
                        }else{
                            this.rejectedCauses[key] = {
                                ...this.cart[key],
                                data: this.latestProducts[key],
                                reason: `Available quantity ${this.latestProducts[key].quantity} is less than order quantity ${this.cart[key].quantity}`,
                                mustRemove: false,
                            }
                        }
                    }
                }
            }
            return Object.keys(this.rejectedCauses).length === 0
        },
        getQuantityPromises(){
            let promises = []
            for(const product of Object.values(this.cart)){
                const p = new Promise((resolve, reject) => {
                    const d = doc(db, 'Products', product.pid,'Variations', product.id)
                    getDoc(d).then((res) => {
                        if(!res.exists()){
                            reject({
                                ...product, 
                                db: null,
                                reason: "This product is no longer available. Please remove",
                                mustRemove: true,
                            })
                        }
                        let data = res.data();
                        this.latestProducts[res.id] = data
                        if (data.quantity >= product.quantity){
                            const u = {
                                quantity: increment(0-product.quantity)
                            }
                            resolve( () => updateDoc(d, u))
                        }else{
                            let reason = `Available quantity ${data.quantity} is less than order quantity ${product.quantity}`
                            if(data.quantity === 0) reason = `This item is no longer in stock. Please remove`
                            reject({
                                ...product,
                                db: data, 
                                reason:reason,
                                mustRemove: data.quantity === 0,
                            })
                        }
                    }).catch(() => {
                        reject({
                            ...product, 
                            db: null,
                            reason: "This product is no longer available. Please remove",
                            mustRemove: true,
                        })
                    })
                })
                promises.push(p)
            }
            return promises
        },
        async subtractQuantities(performUpdate=true){
            if (!this.verifyWithLatest())return false
            this.latestProducts = {}
            let promises = this.getQuantityPromises()
            let values = await Promise.allSettled(promises)
            let allSucceeded = values.reduce((x,y) => y.status == "rejected" ? false : x, true)
            if (!allSucceeded) {
                let rejected = values.filter((x) => (x.status == "rejected"))
                this.rejectedCauses = rejected.reduce((x, y) => {x[y.reason.id] = y.reason; return x}, {})
                console.log("rejected", this.rejectedCauses)
                return false
            }
            if (performUpdate){
                let ps = values.map((r) => r.value)
                console.log("Updates", ps)
                for (let update of ps) update()
            }
            return true
        },
        async createOrder(){
            this.details.createdAt = Timestamp.now()
            this.details.updatedAt = Timestamp.now()
            this.details.cart = this.cart
            this.loading = true
            let valid = await this.subtractQuantities();
            if (!valid){
                this.loading = false;
                Toast.fire({title:'Please Review Your Order',icon:'error',timer:2000})
                return
            }
            const collectionRef = collection(db,'orders');
            addDoc(collectionRef, this.details).then( () => {
                this.displayPanel = 'SUBMITTED'
                this.newCart()
                this.loading = false
                this.setDisplay('DEFAULT');
                Toast.fire({title:'Order Received!',icon:'success',timer:2000})
            })
        },
        getSubtotal(){
            let total = 0;
            for (let product of Object.values(this.cart)){
                total += product.price * product.quantity
            }
            this.details.subtotal = total;
        },
        isSelected(s){
            return this.details.method === s ? "card fulfillment-card mb-3 selected " : "card  fulfillment-card mb-3 "
        },
    },
    computed:{
        ...mapState({
            storePathString: state => state.storePathString,
            storeProducts: state => state.storeProducts,
            cart: state => state.cart,
        }),
        today(){
            let d = new Date(Date.now())
            return [d.getFullYear(), d.getMonth(), d.getDate()].join('-')
        }
    },
    mounted(){
        this.getSubtotal()
        if (!Object.keys(this.latestProducts).length){
            this.subtractQuantities(false)
        }
    },
}
</script>

<style lang="scss" scoped>
.totals{
    font-weight: 600;
    font-size: 1rem;
}

.product-row{
    background: var(--Light);
    .title{
        overflow-y: hidden;
        font-size:1.2rem;
        line-height: 1.4rem;
        min-height: 1.4rem;
        max-height: 1.4rem;
    }
    .field-label{
        min-height:.9rem;
        max-height:.9rem;
        font-size:.8rem;
    }
    .field-value{
        font-size:.8rem;
        min-height:.9rem;
        max-height:.9rem;
        contain:content;
        overflow-x: hidden;
    }
}

table .header{
    background: #909090;
    tr {
        border-radius:.8rem;
    }
}

.subtotal{
    font-size: 1rem;
    backdrop-filter: blur(15px);
    line-height: 1rem;
    min-height: 1rem;
    background: var(--Light);
    color: var(--Dark);   
    .image{
        width:5rem;
        height: auto;
    }
}

.cart-product{
    font-size: .8rem;
    backdrop-filter: blur(15px);
    line-height: 1.4rem;
    min-height: 1.4rem;
    // max-height: 1.4rem;
    background: var(--Light);
    border-radius:.8rem !important;
    color: var(--Dark);
    .text{
        font-weight:600;
    }
    .image{
        height:5rem;
    }
    .title{
        font-size:1.2rem;
        line-height: 1.4rem;
        min-height: 1.4rem;
        max-height: 1.4rem;
        overflow: hidden;
        // max-width:5rem;
        // contain:content;
    }
    .price{
        font-size:1rem;
    }
}

.color-button{
    height:.8rem;
    width: .8rem;
    border-radius: 1.67rem;
}

.card{
    box-shadow:2px 2px 2px #00000021
}

.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}

.contact-form{
    .section-title{
        color:#f1f1f1;
    }
    .form-label{
        visibility: hidden;
    }
    input::placeholder{
        color:#fff;
    }
    input{
        background:none;
        color: #f1f1f1;
        border: 0px;
        border-bottom:3px solid #f1f1f1;
        border-radius: 0px;
        outline:none !important;
        line-height: 1.5rem;
        height: 2rem;
        margin-bottom:.5rem;
    }
    input:focus{
        line-height: 1.5rem;
        height: 2rem;
        outline:none !important;
        background:none;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #f1f1f1;
    }
}

.fulfillment-card{
    background: var(--Dark);
    color: var(--Light);
    border: 2px solid var(--Light)
}

.select,
.selected{
    background: var(--Light);
    color: var(--Dark);
}

.quantity-input{
    height:1.5rem;
    width: auto;
    min-width: 5rem;
    max-width: 50%;
}

.loading-cover{
    height:100vh;
    width: 110vw;
    top:0;
    left:0;
    right:0;
    position:fixed;
    background: #90909090;
    z-index: 999;
}
.loading-focus{
    box-shadow:1px 1px 2px #000;
    vertical-align: center;
}
</style>