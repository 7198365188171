// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';
// import {storage} from 'firebase/app/dist/storage';
// import {}
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCdVEYRAk5KpqLCoY78rMSSum-t3-4DWIw",
    authDomain: "ogstilo702.firebaseapp.com",
    projectId: "ogstilo702",
    storageBucket: "ogstilo702.appspot.com",
    messagingSenderId: "1001152904793",
    appId: "1:1001152904793:web:1b5ba49cf5065f1d5a3b48",
    measurementId: "G-4J3ZV3S05Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const storage = getStorage(app);
const storageRef = ref(storage);
export const auth = getAuth(app);
export const db = getFirestore();
export { storage, storageRef };
// export const storage = getStorage(app);