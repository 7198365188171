<template>
    <div id="wrapper" class="w-100">
		<div class="fixed-bar">
			<nav class="navbar navbar-expand-lg d-flex justify-content-between w-100">
				<div class="">
					<button v-if="display !== 'DEFAULT'" @click="setDisplay('DEFAULT')" type="button" id="backButton" class="btn mx-2">
						<i class="fas fa-home m-auto"></i>
					</button>
					<button  @click="toggleSidebar" type="button" id="sidebarCollapse" class="btn  mx-2">
						<i class="fas fa-align-left"></i>
					</button>
				</div>
				<a role="button" @click="setDisplay('CART')" class="btn cart-button mx-2"><i class="fa fa-cart-shopping cart-icon fa-lg"></i><div class="cart-size bg-light d-inline-block align-content-center">{{Object.keys(cart).length}}</div></a>
			</nav>
		</div>
		<div id="content" class="w-100 d-flex bg-light">
			<side-bar v-if="brands.length && categories.length" v-bind:Menu="getMenu()"></side-bar>
			<main @scroll.passive="$refs.store.handleScroll" class="container-fluid  main-wrap main-wrap-fixed py-2 w-100">
				<Store ref="store"></Store>
			</main>
		</div>
    </div>
</template>
<script>
import SideBar from './Sidebar.vue'
import { mapActions, mapMutations, mapState, } from 'vuex';
import Store from '../views/Store.vue'
export default {
    name:'StoreLayout',
    components:{SideBar, Store},
    data(){
        return {
            isSmallScreen: false,
            collapsed: true,
            brandsLoaded:false,
            categoriesLoaded:false,
            subcategoriesLoaded:false,
        } 
    }, 
    setup(){
    },
    methods:{
        ...mapMutations(['setDisplay','toggleSidebar']),
        ...mapActions(['getCategories','getSubCategories','getBrands','setStorePath','getUser']),
        getMenu(){
            return [
                {
                    title:'All',
                },
                {
                    title: 'Brands',
                    child:this.brands,
                },
                {
                    title: 'Categories',
                    child:this.categories
                },
            ]
        },
        getScreenSize(){
            this.isSmallScreen = screen.availWidth < 760
        },
        sidebarClick(e, item){
            if(item.name){
              this.setDisplay(item.name)
            }
        },
        onCollapse(collapsed){
            this.isCollapsed = collapsed
        },
    },
    mounted(){
        window.addEventListener('resize',this.getScreenSize);
        this.getCategories()
        this.getBrands()    
        this.getUser()
    },
    computed:mapState({
        brands: state => state.brands,
        categories: state => state.categories,
        subCategories: state => state.subCategories,
        path: state => state.storePath,
        cart: state => state.cart,
        display: state => state.displayPanel,
    })

};
</script>
<style scoped lang="scss">
#wrapper{
  overflow: hidden !important;
  overflow:-moz-hidden-unscrollable;
}
#content{
  top:54px;
  position:fixed;
  height:calc(100vh - 54px) !important;
}


.main-wrap{
    position:relative;
    height:calc(100vh - 54px);
    overflow: hidden;
    overflow-y:scroll;
    background: #c6c6c6;
}
@media (max-width:576px) {
    .main-wrap-fixed{
        position:fixed ;
        left:0px;
    }

}
.fixed-bar{
  min-width:100vw;
  min-height:54px;
  top:0px !important;
  position: fixed;
  display:block;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: calc(100vh - 54px);
    max-height: calc(100vh - 54px);
    
    overflow: hidden;
    z-index: 100;
}

#sidebar.active {
    margin-left: -250px;
    
}
#sidebar {
    min-width: 250px;
    max-width: 250px;
    // top:0px;
    // position:fixed;
}
a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
}
p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar {
    /* don't forget to add all the previously mentioned styles here too */
    background: #7386D5;
    color: #fff;
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}
ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

#backButton i {
    margin-right: .8rem;
    padding-left: 0;
}

#backButton,
#sidebarCollapse{
    background: var(--Dark);
    color:var(--Light);
    border: 1px solid var(--Light);
	width:40px;
}

.cart-button{
    border-radius: 1.67rem;
    background: var(--Dark);
    color: var(--Light);
    border: 1px solid var(--Light);
    font-weight: 400;
}

.pl-2{
    padding-left:.6rem;
}

.cart-icon{
    // padding:0px 0px;
    // margin:0px 0px;
    // font-size: ;
    // height: 1.5rem;
}
.cart-icon:focus{
    color:var(--Light);
}

.cart-size{
    border-radius: 1.67rem;
    margin-left:.8rem;
    color:var(--Dark);
    background: var(--Light);
    text-align: center;
    height:1.5rem;
    width:1.5rem;
    font-size:1rem;
    border: 1px solid var(--Dark);
}
</style>