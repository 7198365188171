import { onAuthStateChanged } from "firebase/auth";
import { createRouter, createWebHistory } from "vue-router";
import { auth } from "../firebase";

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "dashboard" */ '../views/Landing.vue'),
        meta: {
            authRequired: false,
            layout: 'Landing',
        }
    },
    {
        path: '/products/:id',
        name: 'Product',
        component: null,
        meta: {
            authRequired: false,
            layout: 'Landing',
        }
    },
    {
        path: '/brands/:brand',
        name: 'Brand',
        component: null,
        meta: {
            authRequired: false,
            layout: 'Landing',
        }
    },
    {
        path: '/store',
        name: 'Store',
        component: () =>
            import ('../views/Store.vue'),
        meta: {
            authRequired: false,
            layout: 'Store',
        }
    },
    {
        path: '/store/cart',
        name: 'Cart',
        component: () =>
            import ('../components/cart/cart.vue'),
        meta: {
            authRequired: false,
            layout: 'Store',
        }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () =>
            import ('../views/Admin.vue'),
        meta: {
            authRequired: true,
            layout: 'Admin',
        }
    },
    {
        path: '/admin/:view/:id',
        name: 'EditProduct',
        component: () =>
            import ('../views/Admin.vue'),
        meta: {
            authRequired: true,
            layout: 'Admin',
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../components/admin/login.vue'),
        meta: {
            authRequired: false,
            layout: 'Landing',
        }
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: null,
        meta: {
            authRequired: false,
            layout: 'Landing',
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async(to, from, next) => {
    const authRequired = to.matched.some(record => record.meta.authRequired);
    const permissions = to.matched.some(record => record.meta.permissions);
    if (authRequired == false && permissions == false) {
        next();
    } else {
        let p = new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    // const uid = user.uid;
                    // ...
                    next()
                    resolve()
                } else {
                    // User is signed out
                    // ...
                    next('/login')
                    reject()
                }
            });
        })
        await p;
    }
})

export default router;