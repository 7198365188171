// eslint-disable 
import { createStore } from 'vuex'
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from '../firebase';
import { doc, onSnapshot, query, startAt, where, collection, orderBy, limit, getDocs } from 'firebase/firestore';
import VuexPersistence from "vuex-persist";
import { onAuthStateChanged } from 'firebase/auth';
// import Cookies from "js-cookie";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    restoreState: (key) => {
        let data = localStorage.getItem(key);
        let cart = localStorage.getItem(key + "Cart")
        if (!cart) cart = {}
        else cart = JSON.parse(cart)
        if (Array.isArray(cart)) {
            let r = {}
            for (let item of cart) {
                r[item.id] ?
                    r[item.id].quantity += item.quantity :
                    r[item.id] = item
                cart = r
            }
        }
        if (!data) data = {}
        else data = JSON.parse(data)
        if (data.latestProductQueryTime != null)
            data.latestProductQueryTime = new Date(data.latestProductQueryTime)
        let now = new Date()
        if (Math.abs(now - data.latestProductQueryTime) > (30 * 60 * 1000))
            data.storeProducts = {}
        data.cart = cart
        let isUser = !!auth.currentUser
        data = {
            ...data,
            isUser: isUser,
            displayPanel: 'DEFAULT'
        }
        return data
    },
    saveState: (key, state) => {
        let { cart, ...rest } = state;
        delete rest.isUser
        delete rest.user
        if (rest.latestProductQueryTime != null)
            data.latestProductQueryTime = data.latestProductQueryTime.toUTCString()
        let data = JSON.stringify(rest)
        let cartData = JSON.stringify(cart)
        localStorage.setItem(key + "Cart", cartData)
        return localStorage.setItem(key, data)
    },
});


const store = createStore({
    state() {
        return {
            count: 0,
            isUser: false,
            lazyLoading: false,
            latestProductQueryTime: null,
            displayPanel: 'DEFAULT',
            adminImages: [],
            sidebarToggle: false,
            brands: [],
            categories: [],
            subCategories: [],
            storeProducts: {},
            storePath: [],
            storePathString: '',
            forceStoreLoad: false,
            cart: {},
            screenWidth: window.screen.width,
        }
    },
    mutations: {
        'SET_ADMIN_IMAGES': (state, images) => {
            state.adminImages = images;
        },
        increment(state) {
            state.count++
        },
        setDisplay(state, n) {
            state.displayPanel = n;
        },
        toggleSidebar(state) {
            state.sidebarToggle = !state.sidebarToggle;
        },
        addVariation(state, id, variation) {
            for (let key of Object.keys(state.storeProducts)) {
                for (let i in state.storeProducts[key]) {
                    if (state.storeProducts[key][i].id === id) {
                        state.storeProducts[key][i].variations.push(variation)
                    }
                }
            }
        },
        disableForceRedirect(state) {
            state.forceStoreLoad = false
        },
        forceStoreRedirect(state, path) {
            state.forceStoreLoad = true
            state.storePath = path
            state.storePathString = path.join('_')
        },
        addToCart(state, variation) {
            if (variation.id in state.cart) {
                state.cart[variation.id].quantity += variation.quantity
            } else {
                state.cart[variation.id] = variation
            }
        },
        removeFromCart(state, variation) {
            let cart = state.cart
            delete cart[variation.id]
            state.cart = cart
        },
        newCart(state) {
            state.cart = {}
        },
        'SET_BRANDS': (state, brands) => {
            state.brands = brands
        },
        'SET_CATEGORIES': (state, categories) => {
            state.categories = categories
        },
        'SET_SUBCATEGORIES': (state, categories) => {
            state.subCategories = categories
        },
        'SET_STORE_PATH': (state, path) => {
            state.storePath = path;
            state.storePathString = path.join('_');
            state.displayPanel = 'DEFAULT';
            if (state.screenWidth < 992) state.sidebarToggle = false
            // this.toggleSidebar()
        },
        'UPDATE_STORE_PRODUCTS': (state, payload) => {
            state.latestProductUpdateTime = new Date()
            state.storeProducts = Object.assign(state.storeProducts, payload)
        },
        setUser(state, user) {
            state.user = user
            state.isUser = true
        },
        lazyLoading(state, status) {
            state.lazyLoading = status
        }
    },
    actions: {
        getUser() {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    this.commit('setUser', user)
                }
            })
        },
        LoadAdminImages() {
            if (this.state.adminImages.length) return;
            const listRef = ref(storage, 'images');
            listAll(listRef)
                .then((res) => {
                    let result = []
                    res.items.forEach((itemRef) => {
                        // All the items under listRef.
                        getDownloadURL(itemRef).then((url) => {
                            result.push(url);
                            this.commit('SET_ADMIN_IMAGES', result)
                        }).catch((err) => {
                            console.log('image url error', err)
                        })

                    })
                }).catch((err) => {
                    console.log(err)
                })
        },

        getBrands() {
            // if (this.state.brands.length) return;
            const unsub = onSnapshot(doc(db, 'Site', 'Brands'), (doc) => {
                let data = doc.data().content;
                this.commit('SET_BRANDS', data)
                unsub()
            })
        },
        getCategories() {
            // if (this.state.categories.length) return;
            const unsub = onSnapshot(doc(db, 'Site', 'Categories'), (doc) => {
                let data = doc.data().content;
                this.commit('SET_CATEGORIES', data)
                unsub()
                this.dispatch('getSubCategories')
            })
        },
        getSubCategories() {
            // if (this.state.subCategories.length) return;
            const unsub = onSnapshot(doc(db, 'Site', 'SubCategories'), (doc) => {
                let data = doc.data().content;
                this.commit('SET_SUBCATEGORIES', data)
                unsub()
                let res = {};
                for (let s of data) {
                    if (!(s.parent in res)) res[s.parent] = [];
                    res[s.parent].push(s)
                }
                let ret = []
                for (let c of this.state.categories) {
                    if (c.title in res) {
                        c.child = res[c.title]
                    }
                    ret.push(c)
                }
                this.commit('SET_CATEGORIES', ret)
            })
        },
        setStorePath(_, path) {
            if (path[0] === 'All') {
                path = ['Home']
            }
            let joined = path.join('_');
            this.commit('SET_STORE_PATH', path);
            if (!(joined in this.state.storeProducts)) {
                let res = {};
                res[joined] = {
                    products: [],
                    meta: {},
                }
                this.commit('UPDATE_STORE_PRODUCTS', res)
                this.dispatch('queryProducts')
            }
        },
        async queryProducts() {
            this.commit("lazyLoading", true)
            let path = this.state.storePath;
            let pathString = this.state.storePathString;
            let data = this.state.storeProducts[pathString]
            let q;
            if (data.products.length == 0) {
                if (path[0] === 'Categories') {
                    q = query(collection(db, 'Products'), orderBy('createdAt', 'desc'), where('category', '==', path[1]), where('subCategory', '==', path[2]), limit(24));
                } else if (path[0] === 'Brands') {
                    q = query(collection(db, 'Products'), orderBy('createdAt', 'desc'), where('brand', '==', path[1]), limit(24));
                } else if (path[0] === "Home") {
                    q = query(collection(db, "Products"), orderBy("title", 'asc'), limit(24))
                } else {
                    return
                }
            }
            let d = await getDocs(q);
            data.meta.lastVisible = d.docs[d.docs.length - 1];
            console.log("loaded products",d.docs.length)
            d.docs.forEach((doc) => {
                let x = doc.data();
                x.id = doc.id;
                x.variations = []
                data.products.push(x)
            })
            let z = {};
            z[pathString] = data
            this.commit("lazyLoading", false)
            this.commit('UPDATE_STORE_PRODUCTS', z);
        },
        async loadMoreProducts() {
            if (this.state.lazyLoading) return;
            let path = this.state.storePath;
            let pathString = this.state.storePathString;
            let data = this.state.storeProducts[pathString]
            if (data.meta.hasAll) return;
            this.commit("lazyLoading", true)
            let q;
            if (path[0] === 'Categories') {
                q = query(collection(db, 'Products'), orderBy('createdAt', 'desc'), where('category', '==', path[1]), where('subCategory', '==', path[2]), limit(16), startAt(data.meta.lastVisible));
            } else if (path[0] === 'Brands') {
                q = query(collection(db, 'Products'), orderBy('createdAt', 'desc'), where('brand', '==', path[1]), limit(16), startAt(data.meta.lastVisible));
            } else if (path[0] === 'Home') {
                q = query(collection(db, 'Products'), orderBy('title', 'asc'), limit(16), startAt(data.meta.lastVisible));
            } else {
                return
            }
            let d = await getDocs(q);
            console.log("loaded products",d.docs.length);
            if (d.docs[d.docs.length - 1].id === data.meta.lastVisible.id) {
                data.meta.hasAll = true
            } else {
                data.meta.lastVisible = d.docs[d.docs.length - 1];
                d.docs.forEach((doc) => {
                    let x = doc.data();
                    x.id = doc.id;
                    x.variations = []
                    data.products.push(x)
                })
            }
            let z = {};
            z[pathString] = data
            this.commit("lazyLoading", false)
            this.commit('UPDATE_STORE_PRODUCTS', z);
        },
    },
    getters: {

    },
    plugins: [vuexLocal.plugin],
})


export default store;