import { createApp } from 'vue'
//Assets
import App from './App.vue'
import router from './router/'
import store from './vuex/'
//Styles
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();



// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.min.css'

import './assets/styles/index.scss'
import './assets/styles/style.scss'
import './assets/styles/screenSizes.scss'


const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon);


// Make BootstrapVue available throughout your project
// app.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// app.use(IconsPlugin)


app.use(router)
app.use(store)
app.mount('#app')