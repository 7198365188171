<template>
    <div  class="row d-flex justify-content-center align-content-start mt-2  ">
        <div class="col-xs-12 col-md-11 col-lg-11 col-xl-10    focus-container">
            <div class="row d-flex justify-content-around">
                <div class="col-xs-12 col-md-6 col-lg-4">
                    <div class="row d-flex justify-content-start pt-0 p-2">
                        <img :src="focusedImage" alt="" class="primary-image p-0 m-0">
                        <div class="col-12 ">
                            <div class="row d-flex justify-content-start my-3">
                                <div v-for="image,i in product.images" :key="i" :src="image" alt="" @click="focusedImage = image" :style="{'background-image': `url(${image})`,'margin-right':'5px'}" class="support-image  br-1">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-8" @click="null">
                    <div class="row d-flex justify-content-start align-content-start h-100">
                        <div class="col-12 mb-3">
                            <div class="row d-flex justify-content-start">
                                <div class="col-11 info-container br-2 pt-0 p-2 ">
                                    <h2 class="product-title">{{product.title}}</h2>
                                    <div class="w-100 d-flex">
                                        <div class="w-75">
                                            <p class="product-description-text pb-0 mb-0">Brand</p>
                                            <p v-if="brandImage > 0" class="product-brand">{{product.brand}}</p>
                                            <div v-else class=" p-0">
                                                <img class="h-100 product-brand-image" :src="brandImage"/>
                                            </div>
                                            <p class="product-description-text pb-0 mb-0 mt-3">Price</p>
                                            <h4 class="product-price mb-0">${{(selectedVariation?.price ? selectedVariation.price : product.price) / 100}}</h4>
                                        </div>
                                    </div>
                                    <p class="product-description-text pb-0 mb-0 mt-3">Description</p>
                                    <div v-html="product.description" class="product-description"></div>
                                </div>

                            </div>
                        </div>
                        <div id="QuantityInput" class="col-12 mb-3 ">
                            <div class="row d-flex justify-content-start">
                                <div class="input-group p-2 m-0">
                                    <div class="input-group-prepend">
                                        <button @click="selected.quantity > 1 ? selected.quantity -= 1:null; verifyVariation()" class="btn btn-secondary">-</button>
                                    </div>
                                    <input v-model="selected.quantity"  @keyup="verifyVariation()" type="number" class="form-control h-100 text-center"/>
                                    <div class="input-group-append">
                                        <button @click="selected.quantity += 1; verifyVariation()" class="btn btn-secondary">+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row d-flex justify-content-start">
                                <button @click="setSize(size)" :class="{'btn  btn-outline-light w-auto mx-2 mb-2': true, 'btn-light text-dark': selected.size == size, 'btn-dark': selected.size !== size}" v-for="size,i in product.variables.sizes" :key="i">{{size}}</button>
                                <button v-if="isUser" @click="editProduct(product)" class="btn btn-primary w-auto mx-2">
                                    <i class="fa fa-pen"></i>
                                </button>
                            </div>
                        </div>
                        <div v-if="product.variables.colors" class="col-12 my-3">
                            <div class="row d-flex align-content-start justify-content-start colors-container">
                                <div @click="setColor(color)" v-for="color,i in product.variables.colors " :key="i+1" :class="{'color-button float-right mx-2': true,'selected':selected.color === color}" :style='{"backgroundColor":color}'></div>
                            </div>
                        </div>
                        <div class="col-12">
                            <button v-if="hasQuantity" @click="addCart()" id="AddToCartButton" class="btn  btn-outline-light my-auto w-100">Add To Cart</button>
                            <button v-else-if="!isLoading && requirementsFilled && hasLoaded" id="OutOfStockButton" class="btn btn-light my-auto w-100" disabled>Out of Stock</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {  query,where, getDocs, collectionGroup, collection } from 'firebase/firestore';
import {db} from '../../firebase';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
});

export default {
    name:'ProductPopup',
    props: ['product'],
    data(){
        return {
            focusedImage: '',
            selected:{
                color:null,
                size:null,
                quantity:1,
            },
            selectedVariation: null,
            p: this.product,
            hasQuantity:false,
            isLoading: false,
            hasLoaded: false,
            brandImage:"",
        }
    },
    methods:{
        ...mapActions([]),
        ...mapMutations(['addVariation','addToCart','setDisplay','forceStoreRedirect']),
        getBrandImage(){
            for (let brand of this.brands){
                if (brand.title === this.product.brand){
                    this.brandImage = brand.image
                    return true
                }
            }
            return false
        },
        editProduct(product){
            window.location.pathname = `/admin/edit-product/${product.id}`
        },
        async setColor(color){
            this.selected.color = color;
            this.focusedImage = this.product.variables.colorImageMap[color];
            await this.verifyVariation();
        },
        async setSize(size){
            this.selected.size = size;
            await this.verifyVariation();
        },
        async checkQuantity(isBase=false){
            let found = false;
            this.hasQuantity = false;
            this.isLoading = false;
            for(let v of this.p.variations){
                if((v.color === this.selected.color && v.size === this.selected.size) || (this.p.variations.length == 1 && (!this.product.variables.colors.length && !this.product.variables.colors.length))){
                    found = true;
                    this.hasLoaded = true
                    this.selectedVariation = v
                    let base = 0
                    if(this.selectedVariation.id in this.cart) base = this.cart[this.selectedVariation.id].quantity
                    if(v.quantity >= this.selected.quantity+base && this.selected.quantity)this.hasQuantity = true;
                    else this.hasQuantity = false                    
                }
            }
            if (!found && !isBase){
                this.isLoading = true
                await this.getVariations(this.product.id,this.selected.size,this.selected.color)
                this.checkQuantity(true)
            }
        },
        async verifyVariation(){
            if (this.requirementsFilled) await this.checkQuantity();            
        },
        requirementsFilled(){
            return ((this.product.variables.colors.length > 0 && this.selected.color ) && (this.product.variables.sizes.length > 0 && this.selected.size ) ) || 
            (this.product.variables.colors.length == 0 && this.product.variables.colors.length == 0 && this.p.variations.length == 1)
        },
        addCart(){
            this.selectedVariation = Object.assign({}, this.selectedVariation, {quantity: this.selected.quantity})
            this.addToCart(this.selectedVariation)
            Toast.fire({title:'Added To Cart',icon:'success',timer:2000})
            this.$emit('close')
        },
        async getVariations( id, size = null, color = null) {
            let whereClauses = [];
            // let whereClauses = [where('pid', '==', id)];
            if (size) whereClauses.push(where('size', '==', size));
            if (color) whereClauses.push(where('color', '==', color));
            let q = query(collection(db, 'Products', id,  'Variations'), ...whereClauses);
            try{

                let d = await getDocs(q);
                console.log("got variations", d.docs.length, "for", id, size, color)
                d.docs.forEach((doc) => {
                    let x = doc.data()
                    x.id = doc.id
                    this.p.variations.push(x)
                })
            }catch(error){
                console.log("could not get variations",error)
            }
        }
    },
    mounted(){
        this.focusedImage = this.product.images[0];
        if(this.product.variables.colors.length === 1)
            this.selected.color = this.product.variables.colors[0];
        if(this.product.variables.sizes.length === 1)
            this.selected.size = this.product.variables.sizes[0];
        if ((this.selected.color !== "" && this.selected.size !== "") || 
            (!this.product.variables.colors.length && !this.product.variables.colors.length) || 
            (this.selected.color !== "" && !this.product.variables.sizes.length) || 
            (this.selected.size !== "" && !this.product.variables.colors.length)){
                this.checkQuantity()
            }
        this.getBrandImage()
    }, 
    computed:{
        ...mapState({
            brands: state => state.brands,
            cart: state => state.cart,
            isUser: state => state.isUser,
        })
    }
}
</script>

<style lang="scss" scoped>

.support-image{
    width:3rem;
    height:3rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}

.color-button{
    width: 2rem;
    height:2rem;
    border-radius:1.67rem;
    border:2px solid #b6b6b6c9;
}
.selected{
    border-color: var(--Secondary);
    height:2.5rem;
    width:2.5rem;

}
.primary-image{
    width:100%;
    height:auto;
    max-height:50vh;
    border-radius:.4rem;
    background:#f1f1f111;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.border-light{
    height:2rem;
    width:2rem;
    border:2px solid #2121213c;
}
#AddToCartButton{
    margin-top:auto;
    margin-bottom:0px;
    border:1px solid #fff;
    background:#212121;
    color:var(--Light);
}
#AddToCartButton:focus,
#AddToCartButton:hover{
    background:#212121;
}
#OutOfStockButton{
    margin-top:auto;
    margin-bottom:0px;
    border:1px solid #212121;
    background:#fff;
    color:#212121;
}
.focus-container{
    color:var(--Light);
}
.info-container{
    // border: 1px solid #21212121;
}
.product-title{
    font-size: 1.8rem;
    font-weight: 600;
}
.product-brand{
    margin-top: 5px;
    font-weight: 700;
    max-height: 3rem;
    content: contain;
    
}
.product-brand-image{
    margin-top: 5px;
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    contain: content;
    background-size:auto 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    
}
.product-description{
    font-size:.7rem;
    font-weight: 500;
}
.product-description-text{
    font-size:.9rem;
    font-weight: 700;
}
.colors-container{
    height:2.5rem;
}

.input-group-prepend :first-child{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
}
.input-group-append :first-child{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
}
#QuantityInput .form-control{
    text-align:center;
    max-width:80px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
</style>